import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/common/layout";
import TagIcon from "../icons/tag.svg";
import PostGrid from "../components/common/grid";
import Seo from "../components/common/seo";

export default function TagPageTemplate({
  data,
  pageContext,
  location,
}: PageProps<Queries.TagQuery>) {
  const { tag }: any = pageContext;
  const title = data.site?.siteMetadata?.title;
  return (
    <Layout isWide={true}>
      <Seo
        title={`Tagged: ${tag} - ${title}`}
        pathName={location.pathname}
        desc={`Photos tagged with '${tag}' on ${title}`}
        origin={location.origin}
      />
      <header className="mb-8 text-center">
        <span className="pr-2">
          <TagIcon />
        </span>
        <h1 className="text-3xl font-bold inline">{tag}</h1>
        <p className="pt-2">
          {`${
            data.posts.totalCount > 1
              ? `a collection of ${data.posts.totalCount} photos`
              : `just ${data.posts.totalCount} photo`
          }`}
        </p>
      </header>
      <PostGrid posts={data.posts} />
    </Layout>
  );
}

export const tagQuery = graphql`
  query Tag($tag: String) {
    posts: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___pubDate], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          altText
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
